import React from 'react';

const VideoPage = () => {
    return (
        <div>
            <iframe src="/Pravilnik_Videonadzor.pdf" width="100%" style={{ border: 'none', height: '100vh' }} title="PDF Viewer" />
        </div>
    );
};

export default VideoPage;
